<template>
  <div class="roomSelect">
    <!-- <div class="nav">
      <div
        :class="['item', { active: curIndex == index }]"
        v-for="(item, index) in navList"
        :key="index"
        @click="curIndex = index"
      >
        {{ item }}
      </div>
    </div> -->
    <div class="list">
      <div
        class="item"
        v-for="(item, index) in roomList"
        :key="index"
        @click="handelClick(item, index)"
      >
        <div class="left">{{ item.text }}</div>
        <div class="right">
          <div class="arrow" v-if="item.hasChildren == 1">
            <img src="./img/arrow-left.png" alt="" />
          </div>
          <div
            :class="['round', { active: activeIndex == index }]"
            v-else
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getSpaceLinkList } from "./api.js";
export default {
  name: "roomSelect",
  components: {},
  props: {},
  data() {
    return {
      roomList: [],
      list: [],
      curIndex: 0,
      activeIndex: -1,
      navList: ["市政机关事务管理局园区", "请选择"],
      num: 0,
    };
  },
  created() {},
  async mounted() {
    this.getSpaceLinkList();
  },
  computed: {},
  watch: {},
  methods: {
    async getSpaceLinkList(parentId = "P1C2A2V1") {
      const params = {
        specifyCollectionTypes: 1,
        parentId,
        lastStageType: 15,
      };
      const res = await this.$axios.get(getSpaceLinkList, { params });
      this.roomList = res.data.list;
    },
    //点击选择房号
    handelClick(item, index) {
      if (item.hasChildren == 1) {
        this.getSpaceLinkList(item.code);
      } else {
        this.activeIndex = index;
        localStorage.setItem("roomCode", JSON.stringify(item));
        this.$router.back();
      }
    },
  },
  beforeDestroy() {},
};
</script>

<style scoped lang="less">
.roomSelect {
  width: 100%;
  height: 100vh;
  box-sizing: border-box;
  padding: 70px 30px 60px;
  display: flex;
  flex-direction: column;
  .list {
    box-sizing: border-box;

    .item {
      padding: 30px;
      display: flex;
      justify-content: space-between;
      border-bottom: 2px solid #ccc;
      .left {
        font-size: 24px;
      }
      .right {
        .arrow {
          img {
            width: 32px;
            height: 32px;
          }
        }
        .round {
          width: 32px;
          height: 32px;
          border: 2px solid #ccc;
          border-radius: 50%;
          box-sizing: border-box;
          &.active {
            background-color: #fff;
            border: 8px solid blue;
          }
        }
      }
    }
  }
  .nav {
    padding-top: 20px;
    display: flex;
    .item {
      cursor: pointer;
      padding: 20px;
      font-size: 24px;
      position: relative;
      &.active {
        position: relative;
        &::before {
          content: "";
          left: 50%;
          transform: translateX(-50%);
          bottom: 0;
          width: 100%;
          height: 2px;
          background-color: red;
          position: absolute;
        }
      }
    }
  }
}
</style>
